.back-btn {
    padding: 10px;
}

.teacher-info {
    width: 61.8229%;
    margin: auto;


    .user-info {
        display: flex;
        align-items: flex-start;
        background-color: #fff;
        font-size: 1.4rem;
        justify-content: center;
        margin-top: 20px;

        .user-img {
            height: 160px;
            width: 172px;
            margin-right: 10px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .user-content {
            margin-top: 30px;

            >p {
                padding: 5px;
            }
        }

    }

    .introduce-video,
    .video {
        width: 79.8319%;
        height: 340px;
        margin: 28px auto;
        border: 20px solid #252525;
    }

    .introduce-p {
        font-size: 1.4rem;
        margin-left: 10%;
        margin-bottom: 4px;
    }

    .introduce-text {
        width: 80%;
        min-height: 200px;
        background-color: aliceblue;
        padding: 10px;
        margin: auto;
    }

    .select-time {
        width: 100%;
        margin: auto;
        padding-bottom: 10px;

        .btn-time {
            display: block;
            height: 44px;
            width: 278px;
            margin: auto;
            border-radius: 20px;
            background-color: #209ed9;
            border: none;
            color: #fff;
            font-size: 1.4rem;
        }
    }

}

@media screen and (max-width: 720px) {
    .teacher-info {
        width: 98%;
        margin: auto;

        .user-info {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            background-color: #fff;
            font-size: 1.4rem;

            .user-img {
                height: 120px;
                width: 132px;
                margin-right: 10px;

                >img {
                    width: 100%;
                    height: 100%;
                }
            }

            .user-content {
                margin-top: 30px;

                >p {
                    padding: 5px;
                }
            }

        }

        .introduce-video,
        .video {
            width: 85%;
            height: 340px;
            margin: 28px auto;
            border: 20px solid #252525;
        }

        .introduce-text {
            width: 100%;
            min-height: 200px;
            height: auto;
            background-color: aliceblue;
            padding: 10px;
        }

        .select-time {
            width: 100%;
            margin: auto;
            padding-bottom: 10px;

            .btn-time {
                display: block;
                height: 34px;
                width: 178px;
                margin: auto;
                border-radius: 20px;
                background-color: #209ed9;
                border: none;
                color: #fff;

            }
        }

    }
}