.teacher-homepage {
    height: 100%;

    .ant-pagination {
        display: none;
    }


}


.time-div:hover {
    color: red;
}

.no-time {
    color: #e3e3e3;
}

.time-div,
.no-time {
    text-align: center;
}

.submit {
    height: 40px;
    margin-top: 4px;

    .submit-btn {
        width: 100px;
        height: 40px;
        border: 1px solid #fff;
        color: #fff;
        border-radius: 5px;
        background-color: blue;
        float: right;
    }
}

@media screen and (max-width: 720px) {

    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        // padding: 0 0;
    }

    .ant-modal-body {
        // padding: 0;
    }

    .ant-table-wrapper {
        overflow: scroll;
    }
}