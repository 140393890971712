.site-calendar-demo-card {
    height: auto;
}

.events {
    width: 5px;
    height: 5px;
    background-color: green;
    border-radius: 50%;


}

.modify {
    width: 100%;
    min-height: 300px;
    height: auto;
    margin: auto;
    background: url(../../../public/assets/modify-bag.jpg) no-repeat;
    background-size: cover;
    margin-top: 10px;
    text-align: center;
    padding-top: 10%;
    font-size: 1.4rem;
    display: flex;
    flex-direction: column;

    .time-content {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border-bottom: 1px solid #FFF;
        align-items: center;

        .change-div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 14px 0;

            >input {
                height: 30px;
                border: 1px solid;
                // margin: 5px 5px;
                font-size: 12px;
                border-radius: 5px;
            }
        }


        .btn {
            button {
                margin-right: 10px;
                background-color: pink;
                font-size: 1.2rem;
                color: #FFF;
                border-radius: 5px;
                margin-bottom: 20px;
            }
        }
    }
}

.ant-picker-calendar-date-content {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

@media screen and (max-width: 720px) {
    .modify {


        .time-content {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #FFF;

            .change-div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 14px 0;

                >input {
                    height: 30px;
                    border: 1px solid;
                    // margin: 5px 5px;
                    font-size: 12px;
                    border-radius: 5px;
                }
            }


            .btn {
                button {
                    margin-right: 0px;
                    background-color: pink;
                    font-size: 1.2rem;
                    color: #FFF;
                    border-radius: 5px;
                    margin-bottom: 20px;
                }
            }
        }
    }

}