.class-record {
    height: 100%;

    .title {
        font-size: 1.2rem;
        padding: 10px;
        // color: #bbb9b9;
    }

    .content {
        overflow-y: scroll;
        height: 85%;
    }

    .footer {
        position: absolute;
        bottom: 0;
        height: auto;
    }
}