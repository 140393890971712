.teacher-homepage {
    width: 100%;
    height: 100%;

    .teacherContent {
        width: 71.45833333%;
        margin: 20px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: 650px;

        .teacher-item {
            margin: 0 1% 20px 1.5%;
            width: auto;
        }
    }
}

@media screen and (max-width: 720px) {
    .teacher-homepage {
        width: 100%;

        .teacherContent {
            width: 100%;
            margin: 20px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .teacher-item {
                margin: 0;
                margin-bottom: 10px;
                width: 50%;
            }
        }
    }
}