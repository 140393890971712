.footer {
    width: 100%;
    height: auto;
    position: relative;

    >img {
        height: 140px;
        width: 100%;
    }

    .footer-img-wap {
        display: none;
    }

    .swiper {
        font-weight: 700;
        text-align: center;

        h2 {
            color: bisque;
            text-align: center;
            font-size: 1.2rem;
            margin-bottom: 2px;
        }

        p {
            color: #fff;
            font-size: 12px;
        }

        width: 30%;
        position: absolute;
        top: 30%;
        left: 35%;
        color: #fff;
    }

}

@media screen and (max-width: 720px) {
    .footer {
        width: 100%;
        height: auto;

        >img {
            height: 100px;
            width: 100%;
        }

        .footer-img-wap {
            display: block;
        }

        .footer-img-pc {
            display: none;
        }

        .swiper {
            >h2 {
                color: bisque;
                text-align: center;
                font-size: 1.2rem;
                margin-bottom: 2px;
            }

            width: 80%;
            position: absolute;
            top: 10%;
            left: 10%;
            text-align: center;
            color: #fff;
        }
    }
}