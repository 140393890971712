.login {
    background: url(../../../public/assets/login_background.jpg) no-repeat;
    height: 100%;

    .login-content {
        width: 52.0833333%;
        height: auto;
        background-color: #fff;
        border: 2px solid #b6eaf7;
        box-shadow: 1px 2px 3px #b6eaf7;
        padding-top: 30px;
        border-radius: 54px;
        display: flex;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        >img {
            width: 50%;
        }

        .login-from {
            text-align: center;
            width: 50%;

            >img {
                margin-bottom: 2vw;
                margin-top: 3vw;
                width: 38.70370%;
            }

            .ipt-div {
                background-color: #edebec;
                border: 1px solid #b6eaf7;
                height: 40px;
                width: 80%;
                margin: auto;
                border-radius: 10px;


                input {
                    width: 100%;
                    height: 100%;
                }
            }

            .ipt-phone {
                display: flex;


                >select {
                    width: 40%;
                    border: none;
                    background-color: #edebec;
                    border-radius: 20px;
                }

                >input {
                    text-align: left;
                    margin-left: 15%;
                }
            }

            .tips-text {
                text-align: left;
                width: 80%;
                margin: auto;
                padding: 4px;
                font-size: 12px;
                color: #e5212d;
            }

            .verification-div {
                display: flex;
                border: none !important;
                background-color: #fff !important;

                >input {
                    background-color: #edebec;
                    border: 1px solid #b6eaf7;
                    height: 40px;
                    border-radius: 10px;
                }

                >img {
                    height: 100%;
                }
            }

            .verification-p {
                text-align: right;
                padding-right: 46px;
            }

            .login-btn {
                margin: auto;
                width: 80%;
                background-color: #e5212d;
                color: #fff;
                height: 40px;
                line-height: 40px;
                border-radius: 20px;
                margin-top: 3vw;
                margin-bottom: 1vw;
            }

            .switch-div {
                margin-bottom: 5px;
            }
        }
    }
}

// 手机端
@media screen and (max-width: 720px) {
    .login {

        .login-content {
            width: 90%;
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: 20px;

            >img {
                width: 100%;
                height: 350px;
                display: none;
            }

            .login-from {
                text-align: center;
                width: 100%;

                >img {
                    margin-bottom: 2vw;
                    margin-top: 3vw;
                    width: 38.70370%;
                }

                .ipt-div {
                    background-color: #edebec;
                    border: 1px solid #b6eaf7;
                    height: 40px;
                    width: 80%;
                    margin: auto;
                    border-radius: 10px;

                    input {
                        width: 100%;
                        height: 100%;
                    }
                }

                .login-btn {
                    margin: auto;
                    width: 80%;
                    background-color: #e5212d;
                    color: #fff;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 10px;
                    margin-top: 5vw;
                    margin-bottom: 4vw;
                }
            }
        }
    }
}