.questionnaire {
    text-align: left;
    width: 60%;
    margin: auto;
    height: 100%;
    font-size: 14px;

    >h2 {
        font-size: 22px;
        margin: 22px 0 22px;
        color: #209ed9;
        text-align: center;
    }

    .red {
        color: red;
    }

    .name-ipt,
    .name-en-ipt {
        border: 1px solid black;
        height: 30px;
        width: 40%;
    }

    .questionnaire-content {
        width: 100%;
        margin-top: 20px;
        height: 100%;


        .item {
            font-size: 18px;
            margin: 20px 0;

            .ant-rate {
                width: 100%;
            }

            .ant-rate-star {
                text-align: center;
                width: 20%;

            }

            .ant-rate .ant-rate-star:not(:last-child) {
                margin-right: 0px;
            }

            .yes {
                display: flex;
                font-size: 14px;
                margin-top: 10px;
                padding-bottom: 5px;
                border-bottom: 1px solid #e3e3e3;

                .left,
                .right {
                    width: 50%;
                    padding: 0 20px;
                }

                .right {
                    text-align: right;
                }

            }

            p {
                height: auto;
                line-height: 1.5;

                >span {
                    color: red;
                }


            }
        }
    }


}

.submit-btn {

    display: block;
    width: 200px;
    height: 40px;
    background-color: #209ed9;
    margin: 10px auto;
    color: #fff;
    font-size: 16px;
}

@media screen and (max-width: 720px) {
    .questionnaire {
        text-align: left;
        width: 90%;
        margin: auto;

        .name-ipt,
        .name-en-ipt {
            border: 1px solid black;
        }
    }
}