.teacher-com {
    height: auto;
    width: 210px;
    min-width: 200px;
    background-color: #209ed9;
    border-radius: 20px;
    padding-bottom: 16px;

    .head-portrait {
        height: 150px;
        width: 55%;
        margin: 0 auto;
        padding: 20px 0 20px;

        >img {
            width: 100%;
            height: 100%;
            // display: inline-block;
            border-radius: 20px;
        }
    }

    .name-text {
        height: 30px;
        width: 81.95488%;
        background-color: #d8d8d8;
        border-radius: 20px;
        margin: 0 auto;
        text-align: center;
        line-height: 34px;
        font-size: 1rem;
        color: #716f70;
    }

    .language-text {
        color: #fff;
        padding-left: 20px;
        margin: 14px 0 14px;
        font-size: 12px;
    }

    .label {
        margin-left: 20px;
        height: 20px;

        .time-label {
            background-color: #fff;
            color: red;
            font-weight: 600;
            padding: 2px 4px;
            border-radius: 4px;
            font-size: 10px;
            margin-right: 2px;
        }
    }

}

@media screen and (max-width: 720px) {
    .teacher-com {
        height: auto;
        width: 45%;
        min-width: 150px;
        background-color: #209ed9;
        border-radius: 15px;
        padding-bottom: 16px;
        margin: auto;

        .head-portrait {
            height: 120px;
            width: 55%;
            margin: 0 auto;
            padding: 20px 0 20px;

            >img {
                width: 100%;
                height: 100%;
                // display: inline-block;
                border-radius: 10px;
            }
        }

        .name-text {
            height: 30px;
            width: 81.95488%;
            background-color: #d8d8d8;
            border-radius: 15px;
            margin: 0 auto;
            text-align: center;
            line-height: 34px;
            font-size: 1rem;
            color: #716f70;
        }

        .language-text {
            color: #fff;
            padding-left: 20px;
            margin: 14px 0 14px;
            font-size: 12px;
        }

        .label {
            margin-left: 20px;

            .time-label {
                background-color: #fff;
                color: red;
                font-weight: 600;
                padding: 2px 4px;
                border-radius: 4px;
                font-size: 10px;
            }
        }

    }

}