.user-info {
    background-color: #e6f5fc;
    height: auto;

    .user-box {
        width: 59.0625%;
        margin: auto;

        .user-content {
            display: flex;
            color: #2c2c2e;
            font-weight: 700;
            margin: 20px;
            justify-content: center;

            >img {
                width: 180px;
                height: 180px;
                margin-right: 40px;
            }

            .user-details {
                margin-top: 0px;

                p {
                    margin-bottom: 10px;
                }

                .pingjia {
                    // background-color: #fff;
                    //rgb(0 0 0 / 8%)
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;

                    .ant-rate {
                        color: red;
                        background-color: #fff;
                        padding: 0 2px;
                        border-radius: 5px;
                    }
                }

                .reacrd-text {
                    margin-top: 4px;
                    height: 30px;
                    background-color: #209ed9;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                }
            }
        }
    }

    .classTable {
        width: 50%;
        margin: auto;
        height: 100%;
    }


}

@media screen and (max-width: 720px) {
    .user-info {
        background-color: #e6f5fc;
        height: auto;

        .user-box {
            width: 90%;
            margin: auto;

            .user-content {
                display: flex;
                justify-content: center;
                color: #2c2c2e;
                font-weight: 700;
                margin-top: 20px;

                >img {
                    width: 120px;
                    height: 120px;
                    margin-right: 40px;
                }

                .user-details {
                    margin-top: 0px;

                    .pingjia {
                        background-color: #fff;
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;

                    }
                }
            }
        }

        .classTable {
            width: 90%;
            margin: auto;
            height: 100%;
        }
    }
}